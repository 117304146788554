import { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildSocket, getSocket } from "./socket";

import { useUser } from "components/helper/userContext";
import { useSession } from "components/helper/sessionContext";
import { DataVoteType } from "types/dataVoteType";
import { QuestionType } from "types/questionTypes";
import { DisplayMode } from "types/sessionType";
import { EVENT } from "types/socketEventsType";

import Layout from "./common/Layout";
import { BasicButton } from "./common/BasicButton";
import VirtualRemoteBridge from "./vote/Quizzbox/DisplayMode/VirtualRemote/VirtualRemoteBridge";
import ChoiceQuestion from "./vote/Quizzbox/DisplayMode/Question/ChoiceQuestion/ChoiceQuestion";
import GroupedQuestion from "./vote/Quizzbox/DisplayMode/Question/GroupedQuestion/GroupedQuestion";
import OrderedQuestion from "./vote/Quizzbox/DisplayMode/Question/OrderedQuestion/OrderedQuestion";
import Deliberation from "./vote/Votebox/Deliberation/Deliberation";
import GroupedDeliberation from "./vote/Votebox/Deliberation/GroupedDeliberation/GroupedDeliberation";
import Election from "./vote/Votebox/Election/Election";
import NumericQuestion from "./vote/Quizzbox/common/NumericQuestion";
import VoteHeader from "./vote/Votebox/common/Header/VoteHeader";
import TagCloudQuestion from "./vote/Quizzbox/DisplayMode/Question/TagCloudQuestion";
import ErrorPage from "./common/ErrorPage";
import { User } from "types/User";
import { DisplayedError } from "services/errors";
import { LS_AUTH_TOKEN_KEY } from "constants/constants";

const SessionPage = () => {
    const i18n = useTranslation();

    const { user, setUser } = useUser();
    const { session, setSocket } = useSession();
    const { onlineCode } = useParams();
    const navigate = useNavigate();

    const [vote, setVote] = useState<DataVoteType | null>(null);
    const [error] = useState<DisplayedError | null>(null);
    const [isSocketError, setIsSocketError] = useState(false);

    useEffect(() => {
        const cleanUpHandler = connectWebsocketToServer(onlineCode as string);

        return cleanUpHandler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineCode]);

    const connectWebsocketToServer = (onlineCode: string): (() => void) => {
        buildSocket(onlineCode as string);
        const socket = getSocket();
        socket.connect();

        socket.on(EVENT.CONNECT, () => {
            setIsSocketError(false);
            setSocket(socket);
            console.log("Socket connected");
        });

        socket.on(EVENT.VOTE_REQUEST, async (vote, { weight }) => {
            (user as User).weight = weight;
            setUser(user);
            setVote(vote.vote);
        });

        socket.on(EVENT.CLOSE_VOTE, () => {
            setVote(null);
        });

        socket.on(EVENT.RELOAD_REQUEST, reloadRequest);

        socket.on(EVENT.RETURN_HOME, redirectToHome);

        socket.on(EVENT.KILL_PARTICIPANTS, () => {
            localStorage.removeItem(LS_AUTH_TOKEN_KEY);
            redirectToHome();
        });

        socket.on(EVENT.SESSION_CHANGE_STATUS, (isActive: boolean) => {
            if (!isActive) {
                setVote(null);
            }
        });

        socket.on(EVENT.CONNECT_ERROR, () => {
            setIsSocketError(true);
            setTimeout(() => {
                socket.connect();
            }, 5000);
        });

        return () => {
            console.log("disconnect socket Session page...");
            socket.disconnect();
            setSocket(null);
        };
    };

    const reloadRequest = () => {
        navigate(`/session/${onlineCode}`);
    };

    const redirectToHome = () => {
        setUser(null);
        localStorage.removeItem(LS_AUTH_TOKEN_KEY);
        navigate(`/login/${onlineCode}`, { replace: true });
    };

    //In case of proxies, the user's answer will be send
    // In reality this case (proxies) is not supposed to exist
    const sendAnswerForDeviceId = (
        answer: string | Array<string>,
        votingDeviceId: string
    ) => {
        const question = vote?.questions[0];

        const data = {
            votingDeviceId: votingDeviceId,
            responseFormatVersion: vote?.responseFormatVersion,
            //If the answer is an array it concerns the answers of the tag cloud
            answers: Array.isArray(answer)
                ? answer.map((ans) => ({
                      questionId: question?.id,
                      answer: ans,
                  }))
                : [
                      {
                          questionId: question?.id,
                          answer: answer,
                      },
                  ],
        };

        getSocket().emit(EVENT.ANSWER, data);
    };

    const sendAnswer = (answer: string | Array<string>) => {
        const proxies = user?.proxies.map((proxy) => proxy);
        const participants = [user, ...(proxies || [])];

        participants.forEach(function (participant: any) {
            sendAnswerForDeviceId(answer, participant?.numTelecoEncrypted);
        });
    };

    const renderNoVotePendingTemplate = () => {
        return (
            <NoVoteStyled>
                <p>{i18n.t("youAreConnected")}</p>
                <p>
                    {i18n.t(`noQuestionPending.${process.env.REACT_APP_THEME}`)}
                </p>
                <p>{i18n.t("pageRefreshAutomatically")}</p>
                <RefreshBtn onClick={() => window.location.reload()}>{`${i18n.t(
                    "refresh"
                )}`}</RefreshBtn>
            </NoVoteStyled>
        );
    };

    const renderComponentVoteTemplate = () => {
        //This check at this point does not make sens
        //It is only used as non-null typing
        //Makes User not null for linter
        if (!user) {
            return;
        }

        if (!vote) {
            return renderNoVotePendingTemplate();
        }

        const responseFormatVersion = vote.responseFormatVersion;

        if (vote.questions.length > 1) {
            return (
                <GroupedDeliberation
                    title={vote.voteTitle}
                    questions={vote.questions}
                    user={user}
                    proxies={user.proxies}
                    responseFormatVersion={responseFormatVersion}
                />
            );
        }

        const question = vote.questions[0];

        if (user.hasGivenProxy) {
            return (
                <>
                    <VoteHeader question={question} />
                    <StyledNoVoteParagraph>{`${i18n.t(
                        "noVoteCauseProxy"
                    )}`}</StyledNoVoteParagraph>
                </>
            );
        }

        //Always return InputComponent for tag cloud
        if (question?.type === QuestionType.TAG_CLOUD) {
            return (
                <TagCloudQuestion
                    title={question.title}
                    onValidate={sendAnswer}
                    numberOfInputs={question.maxNumberOfAnswers}
                    userCanSubmitMultipleTimes={question.multiSubmit}
                />
            );
        }

        //There are two display modes: Question and Virtual Remote
        let displayMode = session?.displayMode;

        if (!displayMode) {
            switch (question?.type) {
                case QuestionType.DELIBERATION:
                case QuestionType.ELECTION:
                    displayMode = DisplayMode.QUESTION;
                    break;
                default:
                    displayMode = DisplayMode.VIRTUAL_REMOTE;
            }
        }

        if (displayMode === DisplayMode.VIRTUAL_REMOTE) {
            return (
                <VirtualRemoteBridge
                    vote={question}
                    onValidate={sendAnswer}
                    color={session?.telecommandeParticipantCSS as string}
                    currentUser={user}
                />
            );
        }

        switch (question?.type) {
            case QuestionType.NUMERICAL_CORRECT_ANSWER:
            case QuestionType.NUMERICAL:
            case QuestionType.ROLL_CALL:
                return (
                    <NumericQuestion
                        title={question.title}
                        onValidate={sendAnswer}
                    />
                );
            case QuestionType.MULTIPLE_CHOICE_CORRECT_ANSWER:
            case QuestionType.MULTIPLE_CHOICE:
            case QuestionType.UNIQUE_CHOICE:
            case QuestionType.UNIQUE_CHOICE_CORRECT_ANSWER:
            case QuestionType.LIST:
            case QuestionType.TEAMS:
            case QuestionType.CLASSIFICATION:
                return (
                    <ChoiceQuestion
                        onValidate={sendAnswer}
                        title={question.title}
                        minNumberOfAnswers={question.minNumberOfAnswers}
                        maxNumberOfAnswers={question.maxNumberOfAnswers}
                        options={question.propositions}
                    />
                );
            case QuestionType.GROUPED:
                return (
                    <GroupedQuestion
                        onValidate={sendAnswer}
                        title={question.title}
                        subTitles={question.subTitles}
                        options={question.propositions}
                    />
                );
            case QuestionType.ORDERED_ANSWERS:
            case QuestionType.CORRECT_ORDERED_ANSWERS:
                return (
                    <OrderedQuestion
                        onValidate={sendAnswer}
                        options={question.propositions}
                        title={question.title}
                    />
                );
            case QuestionType.DELIBERATION:
                return (
                    <Deliberation
                        question={question}
                        user={user}
                        proxies={user.proxies}
                        responseFormatVersion={responseFormatVersion}
                    />
                );
            case QuestionType.ELECTION:
                if (vote.responseFormatVersion !== 1) {
                    return (
                        <Election
                            question={question}
                            user={user}
                            proxies={user.proxies}
                            responseFormatVersion={responseFormatVersion}
                        />
                    );
                }
                break;
        }

        return renderNoVotePendingTemplate();
    };

    if (error) {
        return <ErrorPage error={error} />;
    }

    if (!user) {
        return <Navigate replace to={`/login/${onlineCode}`} />;
    }

    if (user.onlineCode !== onlineCode) {
        //Setting timeout trick makes this operation safe
        //https://stackoverflow.com/a/69236626/22263049
        setTimeout(() => setUser(null), 0);
        return <Navigate replace to={`/login/${onlineCode}`} />;
    }

    return (
        <Layout isSocketError={isSocketError}>
            <>
                <StyledName>{user.displayName}</StyledName>
                {renderComponentVoteTemplate()}
            </>
        </Layout>
    );
};

const NoVoteStyled = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    font-size: 14px;
    > p {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`;

const StyledName = styled.h1`
    font-size: 14px;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    color: #626262;
`;

const StyledNoVoteParagraph = styled.h1`
    font-size: 14px;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    color: #d9001b;
    font-weight: 100;
`;

const RefreshBtn = styled(BasicButton)`
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    background-color: white;
    color: black;
    width: 9rem;
    margin-top: 0.5rem;
    :hover {
        cursor: pointer;
        filter: brightness(0.8);
    }
`;

export default SessionPage;
