import React, { useState, useContext, createContext, useEffect } from "react";
import { SessionType } from "../../types/sessionType";
import { Socket } from "socket.io-client";

type SessionContextType = {
    session: SessionType | null;
    setSession: React.Dispatch<React.SetStateAction<SessionType | null>>;
    socket: Socket | null;
    setSocket: React.Dispatch<React.SetStateAction<Socket | null>>;
};

type SessionContextProviderProps = {
    children: React.ReactNode;
};

const SessionContext = createContext<SessionContextType>({
    session: null,
    setSession: () => {},
    socket: null,
    setSocket: () => {},
});

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider = ({
    children,
}: SessionContextProviderProps) => {
    const [session, setSession] = useState<SessionType | null>(() => {
        const localSession = localStorage.getItem("session");
        return localSession ? JSON.parse(localSession) : null;
    });

    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        localStorage.setItem("session", JSON.stringify(session));
    }, [session]);

    return (
        <SessionContext.Provider
            value={{ session, setSession, socket, setSocket }}
        >
            {children}
        </SessionContext.Provider>
    );
};
